import React from "react"
import Layout from "../components/layout/Layout"
import SideProjects from "../components/projects/SideProjects"
import Main from "../components/pageLayout/Main"
import Sidebar from "../components/pageLayout/Sidebar"
import Content from "../components/pageLayout/Content"
import ServicesNav from "../components/services/ServicesNav"
import ServicesNavMob from "../components/services/ServicesNavMob"
import Banner from "../components/header/Banner"
import SEO from "../components/SEO"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer' 
import { graphql } from "gatsby"

export const query = graphql`
  query($slug: String!) {
    contentfulSluzby(slug: {eq: $slug}) {
      nazevSluzby
      kratkyPopis
      googleNazev
      hlavniPopis {json}
      googlePopis
      slug
      hlavniObrazek {
        fluid (maxWidth: 1600, quality: 60) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }`

 const Home = ({data}) => {
  const {contentfulSluzby : { 
    nazevSluzby,
    googleNazev,
    googlePopis,
    hlavniPopis,
    hlavniObrazek }} = data;  

  return (
    <Layout>
      <SEO title={googleNazev && googleNazev} article description={googlePopis && googlePopis}/>
      <Banner heading={nazevSluzby} img={hlavniObrazek.fluid}/>
      <Main>
        <Sidebar>
          <ServicesNav/>
          <ServicesNavMob/>
          <SideProjects/>
        </Sidebar>
        <Content> 
          <h3 className="no-line">{nazevSluzby}</h3>
          {documentToReactComponents(hlavniPopis.json)} 
        </Content>
      </Main>
    </Layout>
    )
}

export default Home;
