import React, { useState } from "react"
import styles from "./ServicesNav.module.scss"
import AnimateHeight from "react-animate-height"
import { MdArrowDropDown } from "react-icons/md"
import ServicesLinks from "./ServicesLinks"

const ServicesNav = () => {
  const [height, setHeight] = useState(0)
  const toggleHeight = () => {
    height === 0 ? setHeight("auto") : setHeight(0)
  }

  return (
    <nav className={`${styles.nav} ${styles.navMob}`}>
      <button
        onClick={toggleHeight}
        aria-expanded={height !== 0}
        aria-controls="dropdown-services"
        className={styles.button}
      >
        Služby
        <MdArrowDropDown
          className={`${styles.icon} ${height !== 0 ? styles.iconActive : ""}`}
        />
      </button>
      <AnimateHeight id="dropdown-services" duration={400} height={height}>
        <ServicesLinks />
      </AnimateHeight>
    </nav>
  )
}

export default ServicesNav
