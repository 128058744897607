import React from "react"
import styles from "./ServicesNav.module.scss"
import ServicesLinks from "./ServicesLinks"

const ServicesNav = () => {
  return (
    <nav className={styles.nav}>
      <h3 className="sideHeading">Služby</h3>
      <ServicesLinks />
    </nav>
  )
}

export default ServicesNav
